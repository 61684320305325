import React from 'react';

const AddPage = () => {
    return (
        <div>
        <h2>Page Ajouter</h2>
        <p>C'est la page où vous pouvez ajouter des éléments.</p>
        </div>
    );
};

export default AddPage;
