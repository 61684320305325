import React from 'react';

const SettingsPage = () => {
    return (
        <div>
        <h2>Page Paramètres</h2>
        <p>C'est la page des paramètres de l'application.</p>
        </div>
    );
};

export default SettingsPage;
