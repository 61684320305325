import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import SignIn from './ComponentLogin/SignIn'; // Assurez-vous que le chemin vers SignIn est correct

const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();
    
    
    return (
        <div>
        <SignIn/>
        </div>
    );
};

export default Login;
