import React from 'react';
import { Link } from 'react-router-dom';
import './BottomMenu.css';

const BottomMenu = () => {
    return (
        <div className="bottom-menu">
        <Link to="/account" className="menu-item">
        <i className="icon-account"></i>
        <span>Compte</span>
        </Link>
        <Link to="/add" className="menu-item">
        <i className="icon-add"></i>
        <span>Ajouter</span>
        </Link>
        <Link to="/friends" className="menu-item">
        <i className="icon-friends"></i>
        <span>Amis</span>
        </Link>
        <Link to="/settings" className="menu-item">
        <i className="icon-settings"></i>
        <span>Paramètres</span>
        </Link>
        </div>
    );
};

export default BottomMenu;
