import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        mode: 'light', // ou 'dark' pour le mode sombre
        primary: {
            main: '#1976d2',
        },
    },
});

export default function AppTheme({ children }) {
    return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
