import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import App from './App';

import CreatePost from './CreatePost';

import ListBeer from './ListBeer'; 
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import { useState } from 'react';

ReactDOM.render(
  <App></App>,
  document.getElementById('root')
);
