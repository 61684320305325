import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import ListBeer from './ListBeer';
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';

const BeerApp = () => {
    const maxVolume = 50;
    const [title, setTitle] = useState('');
    const [volume, setVolume] = useState(0);
    const [alcohol, setAlcohol] = useState(0);
    const [glassHeight, setGlassHeight] = useState(0);
    const [message, setMessage] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);
    const [refreshList, setRefreshList] = useState(false);
    const [loading, setLoading] = useState(false); // Indicateur de soumission
    const glassContainerRef = useRef(null);
    const [openModal, setOpenModal] = useState(false);
    
    const calculateAlcoholHeight = () => {
        if (glassHeight === 0) return 0;
        const fillHeight = (volume / maxVolume) * glassHeight;
        const alcoholHeight = (alcohol / 100) * fillHeight;
        return alcoholHeight;
    };
    
    useEffect(() => {
        if (glassContainerRef.current) {
            const height = glassContainerRef.current.offsetHeight;
            setGlassHeight(height);
        }
    }, []);
    
    const updateFill = (percentage) => {
        const volumeInCl = Math.round((percentage / 100) * maxVolume);
        setVolume(volumeInCl);
    };
    
    const updateAlcoholFill = (e) => {
        setAlcohol(e.target.value);
    };
    
    const handleFill = (clientY) => {
        const rect = glassContainerRef.current.getBoundingClientRect();
        const offsetY = clientY - rect.top;
        const height = rect.height;
        const percentage = 100 - (offsetY / height) * 100;
        const clampedPercentage = Math.max(0, Math.min(100, percentage));
        updateFill(clampedPercentage);
    };
    
    const handleFileChange = (e) => {
        setSelectedFile(e.target.files[0]);
    };
    
    const handleOpenModal = () => {
        setOpenModal(true);
    };
    
    const handleCloseModal = () => {
        setTitle(''); // Réinitialiser le titre
        setVolume(0); // Réinitialiser le volume à 0
        setAlcohol(0); // Réinitialiser l'alcool à 0
        setOpenModal(false);
    };
    
    const handleCreatePost = async () => {
        if (!title) {
            setMessage('Le titre est obligatoire.');
            return;
        }
        setLoading(true); // Démarrer le chargement
        setMessage('Envoi en cours...');
        const token = localStorage.getItem('token');
        if (!token) {
            setMessage('Erreur : utilisateur non authentifié.');
            setLoading(false);
            return;
        }
        
        let imageId = null;
        
        // Si un fichier est sélectionné, téléverser l'image fournie par l'utilisateur
        if (selectedFile) {
            const formData = new FormData();
            formData.append('file', selectedFile);
            
            try {
                const response = await axios.post(
                    'https://tagadatravel.com/wordpress/wp-json/wp/v2/media',
                    formData,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                            'Content-Type': 'multipart/form-data',
                        },
                    }
                );
                imageId = response.data.id; // Récupérer l'ID de l'image téléversée
            } catch (error) {
                setMessage('Erreur lors du téléversement de l\'image.');
                setLoading(false);
                return;
            }
        } else {
            // Sinon, utiliser une image de verre par défaut
            imageId = 123; // Utilisez une ID d'image par défaut ou capturez depuis un canevas
        }
        
        try {
            const response = await axios.post(
                'https://tagadatravel.com/wordpress/wp-json/wp/v2/posts',
                {
                    title: title,  // Utiliser le titre entré par l'utilisateur
                    content: `Le verre contient ${volume} cl avec un taux d'alcool de ${alcohol}%`,
                    status: 'publish',
                    featured_media: imageId,
                    meta: {
                        volume: volume,
                        alcool: alcohol,
                    }
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                }
            );
            setMessage(`Publication créée avec succès : ${response.data.title.rendered}`);
            setRefreshList(!refreshList);
            setTimeout(() => {
                setMessage('');
                setLoading(false); // Arrêter le chargement après la réussite
            }, 3000);
        } catch (error) {
            setMessage('Erreur lors de la création de la publication.');
            setLoading(false);
        }
    };
    
    return (
        <div>
        <Fab style={{display:"block",margin:"20px auto"}} onClick={handleOpenModal} color="primary">
        +
        </Fab>
        
        <Dialog open={openModal} onClose={handleCloseModal} maxWidth="sm" fullWidth>
        <DialogTitle>Créer un post avec votre bière</DialogTitle>
        <DialogContent>
        
        {/* Champ pour le titre */}
        <TextField
        label="Titre du Post"
        variant="outlined"
        fullWidth
        margin="normal"
        value={title}
        onChange={(e) => setTitle(e.target.value)} // Mettre à jour l'état du titre
        />
        
        <div
        className="glass-container"
        ref={glassContainerRef}
        onMouseDown={(e) => handleFill(e.clientY)}
        style={{ width: '140px', height: '200px', position: 'relative', overflow: 'hidden', borderRadius: '10px', margin: 'auto' }}
        >
        <img className="image_verrer" src="/biere_50cl.png" alt="Verre de bière" style={{ height: '100%', position: 'absolute', zIndex: 1, userSelect: 'none', pointerEvents: 'none' }} />
        <div
        className="glass-fill"
        style={{
            height: `${(volume / maxVolume) * 100}%`,
            transition: 'height 0.7s ease-in-out',
            position: 'absolute',
            bottom: 0,
            width: '100%',
            backgroundColor: '#ffae00',
        }}
        >
        <div
        className="glass-fill-alcool"
        style={{
            height: `${calculateAlcoholHeight()}px`,
            transition: 'height 0.7s ease-in-out',
            backgroundColor: 'rgba(255, 197, 132, 0.7)',
            position: 'absolute',
            bottom: 0,
            width: '100%',
        }}
        />
        </div>
        </div>
        
        <div className="percentage-display">{volume} cl</div>
        
        <div className="range-slider">
        <input
        className="alcohol-slider"
        type="range"
        value={alcohol}
        min="0"
        max="50"
        onChange={updateAlcoholFill}
        style={{
            width: '100%',
            margin: '10px 0',
            appearance: 'none',
            height: '15px',
            background: `linear-gradient(to right, #ffc584 ${alcohol * 2}%, #e0e0e0 ${alcohol * 2}%)`,
        }}
        />
        <span className="percentage-display alcohol-percentage range-value">{alcohol}%</span>
        </div>
        
        <div>
        <label>Téléverser une image :</label>
        <input type="file" onChange={handleFileChange} accept="image/*" />
        </div>
        </DialogContent>
        <DialogActions>
        <Button onClick={handleCloseModal}>Annuler</Button>
        <Button 
        variant="contained" 
        onClick={() => { handleCreatePost(); handleCloseModal(); }} 
        disabled={loading} // Désactiver le bouton si l'envoi est en cours
        >
        {loading ? 'Envoi en cours...' : 'Slurp !'}
        </Button>
        </DialogActions>
        </Dialog>
        
        <ListBeer refreshList={refreshList} />
        </div>
    );
};

export default BeerApp;
