import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import AddPage from './AddPage';
import BeerApp from './BeerApp'; // Simulateur de bière
import { AuthProvider } from './AuthContext';
import PrivateRoute from './PrivateRoute'; // Composant pour les routes protégées
import Login from './Login';

import SettingsPage from './SettingsPage';
import 'bootstrap/dist/css/bootstrap.min.css';
import "./App.css";
import FriendsPage from './FriendsPage';
import BottomMenu from './BottomMenu';
const App = () => {
  return (
    
    <Router>  <AuthProvider>
    <div className='container-page'>
    
    
    <Routes>
    <Route path="/login" element={<Login />} />
    
    {/* Route protégée, accessible seulement si l'utilisateur est connecté */}
    <Route path="/account" element={
      <PrivateRoute>
      <BeerApp />
      </PrivateRoute>
    } />
    {/* Route protégée, accessible seulement si l'utilisateur est connecté */}
    <Route path="/" element={
      <PrivateRoute>
      <BeerApp />
      </PrivateRoute>
    } />
    
    <Route path="/add" element={
      <PrivateRoute>
      <AddPage />
      </PrivateRoute>
    } />
    <Route path="/settings" element={
      <PrivateRoute>
      <SettingsPage />
      </PrivateRoute>
    } />
    <Route path="/friends" element={
      <PrivateRoute>
      <FriendsPage />
      </PrivateRoute>
    } />
    
    </Routes>  
    <BottomMenu />
    </div>
    </AuthProvider>
    </Router>
    
  );
};

export default App;
