import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';


const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const ListBeer = ({ refreshList }) => {
    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [selectedImage, setSelectedImage] = useState(null);
    const [showModal, setShowModal] = useState(false);
    
    const getUserIdFromToken = () => {
        const token = localStorage.getItem('token');
        if (!token) {
            setError('Erreur : utilisateur non authentifié');
            return null;
        }
        
        try {
            const decodedToken = jwtDecode(token);
            return decodedToken.data.user.id;
        } catch (err) {
            setError('Erreur lors du décodage du token JWT.');
            return null;
        }
    };
    
    const fetchFeaturedImage = async (mediaId) => {
        if (!mediaId) return null;
        try {
            const response = await axios.get(`https://tagadatravel.com/wordpress/wp-json/wp/v2/media/${mediaId}`);
            return response.data.source_url;
        } catch (error) {
            console.error('Erreur lors de la récupération de l\'image:', error);
            return null;
        }
    };
    
    const fetchUserPosts = async (userId) => {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.get(
                `https://tagadatravel.com/wordpress/wp-json/wp/v2/posts?author=${userId}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            const postsWithImagesAndMeta = await Promise.all(
                response.data.map(async (post) => {
                    const imageUrl = await fetchFeaturedImage(post.featured_media);
                    return { 
                        ...post, 
                        imageUrl, 
                        volume: post.meta?.volume,  // Récupérer le volume
                        alcool: post.meta?.alcool,
                        date: post.date      // Récupérer l'alcool
                    };
                })
            );
            setPosts(postsWithImagesAndMeta);
            setLoading(false);
        } catch (err) {
            console.error("Erreur lors de la récupération des posts:", err);
            setError('Erreur lors de la récupération des posts.');
            setLoading(false);
        }
    };
    
    useEffect(() => {
        const userId = getUserIdFromToken();
        if (userId) {
            fetchUserPosts(userId);
        }
    }, [refreshList]);
    
    const handleImageClick = (imageUrl) => {
        setSelectedImage(imageUrl);
        setShowModal(true);
    };
    
    if (loading) {
        return <p>Chargement des posts...</p>;
    }
    
    if (error) {
        return <p>{error}</p>;
    }
    
    return (
        <div className="container mt-4">
        <h2>Mes Publications</h2>
        {posts.length === 0 ? (
            <p>Aucune publication trouvée.</p>
        ) : (
            <TableContainer component={Paper}>
            <Table  aria-label="posts table">
            
            <TableBody>
            {posts.map((post) => (
                <TableRow key={post.id}>
                <TableCell sx={{ Width: 0 }}>
                <Avatar
                alt="Post Image"
                src={post.imageUrl ? post.imageUrl.replace("localhost", "192.168.1.21") : 'https://via.placeholder.com/150'}
                style={{ cursor: 'pointer' }}
                onClick={() => post.imageUrl && handleImageClick(post.imageUrl)}
                />
                </TableCell>
                <TableCell>
                <p style={{ margin : "0 0 2px 0" }}> {post.title.rendered}</p>
                <p style={{ margin : "0 0 2px 0" }}>{post.volume ? post.volume+'cl' : 'N/A'} - {post.volume ? post.alcool+"%" : 'N/A'}</p>
                <p style={{ margin : "0" }}> {new Date(post.date).toLocaleDateString('fr-FR', {
                    weekday: 'long',  // Ajoute le jour de la semaine (facultatif)
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric'
                })}</p>
                </TableCell>
                
                
                </TableRow>
            ))}
            </TableBody>
            </Table>
            </TableContainer>
        )}
        
        {showModal && (
            <Modal
            open={showModal}
            onClose={() => setShowModal(false)}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
            >
            <Box sx={modalStyle}>
            <Typography id="modal-title" variant="h6" component="h2">
            Image
            </Typography>
            <img src={selectedImage.replace("localhost", "192.168.1.21")} alt="Large view" className="img-fluid" />
            <button type="button" onClick={() => setShowModal(false)}>Fermer</button>
            </Box>
            </Modal>
        )}
        </div>
    );
};

export default ListBeer;
