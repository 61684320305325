import React from 'react';

const FriendsPage = () => {
    return (
        <div>
        <h2>Page Amis</h2>
        <p>C'est la page pour gérer vos amis.</p>
        </div>
    );
};

export default FriendsPage;
